import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import '../../pages/style.css';


const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    @media (max-width: 768px) {
        display:flex;
        flex-direction: column;
        height: 100%;
  }
`
const Statement = styled.h1 `
    max-width: 100%;
    font-family: "Averia Sans Libre";
    font-size: 62px;
    color: #3b3b3b;
    font-weight: 700;
    margin-bottom: 0px;
    @media (max-width: 1680px) {
        font-size: 48px;
        max-width: unset;
    }
    @media only screen and (max-width: 968px) {
        font-size: 30px;
    }
`
const Subtitle = styled(Statement) `
    max-width: 100%;
    font-family: "Open Sans";
    font-size: 32px;
    color: #6e6e6e;
    text-decoration: none;
    font-weight: 300;
    margin-bottom: 40px;
    @media (max-width: 1680px) {
        font-size: 20px;
        max-width: unset;
        margin-bottom: 0px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 18px;
    }
`
const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (max-width: 1680px) {
        padding: 2rem;
        justify-content:left;
        width: unset;
    }
`
const CardBox = styled.div`
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 5rem;
    grid-gap: 24px;
    justify-content: space-around;
    height: 100%;
    background-color: transparent;
    padding: 0rem 5rem 0rem 5rem;
    @media (max-width: 768px) {
        margin-top: unset;
        display:flex;
        flex-direction: column;
        padding: 2rem;
    }
`
const Card = styled.div`
    border-radius: 5px;
    margin-top: 24px;
    padding: 24px;
    max-height: 80%;
    box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.06);
    @media (max-width: 768px) {
        margin-top: unset;
        max-height: 70%;
    } 
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px;
`


const Hiw = () => {
    return(
        <Conatiner id="hiw">
            <Header>
                <Statement>How does it work <span style={{color:"#0B315E"}}>?</span> </Statement>
                <Subtitle>Clear and simple for fast progress reports</Subtitle>
            </Header>
            <CardBox>
                <Card>
                    <div>
                        <ImageContainer>
                            <StaticImage src="../../images/Group-336.png" />
                        </ImageContainer>
                    </div>
                    <div>
                        <h3>Step 1</h3>
                        <h4>Link your Gitlab account (we use secure tokens) quickly and easily</h4>
                    </div>
                </Card>
                <Card>
                    <div>
                        <ImageContainer>
                            <StaticImage src="../../images/account.png" />
                        </ImageContainer>
                    </div>
                    <div >
                        <h3>Step 2</h3>
                        <h4>Select your group(s) and team member(s) for tracking and reporting progress</h4>
                    </div>
                </Card>
                <Card>
                    <div >
                        <ImageContainer>
                            <StaticImage src="../../images/pie-chart.png" />
                        </ImageContainer>
                    </div>
                    <div>
                        <h3>Step 3</h3>
                        <h4>Start generating Gantt Views, Pie Charts, Detailed Activity views, and more..</h4>
                    </div>
                </Card>
            </CardBox>
        </Conatiner>
    )
}

export default Hiw