import React from 'react';
import styled from 'styled-components';
import HeroSVG from "../../images/hero-svg.inline.svg"
import '../../pages/style.css';

const Container = styled.div `
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    overflow: visible;
    @media (max-width: 768px) {
        height: 100%;
  }
`
const HeroSection = styled.div `
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 5rem 5rem 0rem 5rem;
    height: 100%;
    @media (max-width: 768px) {
        display:flex;
        flex-direction: column;
        margin: 2rem;
  }
`
const ActionContainer = styled.div `
    display: flex;
    flex-direction: column;
    padding-top: 8vh;
    height: 100%;
    overflow: visible;
`
const Statement = styled.h1 `
    max-width: 100%;
    font-family: "Averia Sans Libre";
    font-size: 62px;
    color: #3b3b3b;
    text-decoration: none;
    font-weight: 700;
    margin-bottom: 24px;
    @media (max-width: 1680px) {
        font-size: 48px;
    }
`
const Statement2 = styled(Statement) `
    max-width: 100%;
    font-family: "Open Sans";
    font-size: 32px;
    color: #6e6e6e;
    text-decoration: none;
    font-weight: 300;
    margin-bottom: 40px;
    @media (max-width: 1680px) {
        font-size: 20px;
    }
`
const DefaultButton = styled.a `
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: 24px;
    height: 0px;
    background-color: #f08421;
    border-radius: 100px;
    font-size: 16px;
    font-family: "Open Sans";
    color: white;
`
const SVGcontainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 5rem;
    overflow: visible;
    @media (max-width: 768px) {
        padding: 2rem;
        z-index: -99;
  }
`
const CallToAction = styled.div`
    margin-top: 10vh;
    max-width: 500px;
    display:flex;
    justify-content: space-between;
    border-radius: 5px;
    align-items: center;
    box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.06);
    padding: 24px;
    @media (max-width: 768px) {
        margin-top: 0rem;
  }
`
const BodyFont = styled.p `
    font-family: "Open Sans";
    font-size: 20px;
    color: #3b3b3b;
    font-weight: 400;
    @media (max-width: 1680px) {
        font-size: 20px;
    }
`
const Hero = () => {
    return(
        <Container id="hero">
            {/* <Topanimation /> */}
            <HeroSection>
                <ActionContainer>
                    <Statement>Manage your team members progress<span className="yellowPoint">.</span></Statement>
                    <Statement2>& produce excellent and clear visuals for your Product Owners</Statement2>
                    <CallToAction>
                        <BodyFont>Try gitdone for free!</BodyFont>
                        <DefaultButton href="https://app.gitdone.io/">Sign in</DefaultButton>
                    </CallToAction>
                </ActionContainer>
                <SVGcontainer>
                    <HeroSVG className="heroSVG"/>
                </SVGcontainer>
            </HeroSection>
        </Container>
    )
}

export default Hero