import React, {useState} from 'react';
import styled from 'styled-components';
import { Link } from "react-scroll";
import Logo from "../images/logo.inline.svg"
import { FiMenu } from "react-icons/fi";
import { FiX } from "react-icons/fi";

const Container = styled.div`
    width: 100%;
    height: 10vh;
    position: fixed;
    top: 0;
    display:flex;
    justify-content: space-around;
    z-index: 1;
    background: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(4px);
    @media only screen and (max-width: 968px) {
        flex-direction: column;
        height: 15vh;
    }
`
const LogoContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    height: 10vh;
    margin-left:5rem;
    @media (max-width: 768px) {
        align-items: left;
        margin-left:2rem;
  }
`
const Menu = styled.div`
    min-width: 40vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    transition: 300ms ease-in-out;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: unset;
        position: absolute;
        padding-top: 2rem;
        z-index: 998;
        background: #f1f1f1;
        width: 100%;
        top:10vh; 
        height: 90vh;
  }
`
const MenuItem = styled(Link)`
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: .6;
    text-decoration: none;
    @media (max-width: 768px) {
        padding-top: 2rem;
        font-size: 24px;
    }
    &:hover {
        cursor: pointer;
        color: #f08421;
    }
`

const MenuItemDoc = styled.a`
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: 400;
    color: #000000;
    opacity: .6;
    text-decoration: none;
    @media (max-width: 768px) {
        padding-top: 2rem;
        font-size: 24px;
    }
    &:hover {
        cursor: pointer;
        color: #f08421;
    }
`

const User = styled.div`
    width:100%;
    margin-right:5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 768px) {
        display: none;
  }
`
const Corchete = styled.p`
    font-family: "lato";
    font-size: 16px;
    font-weight: 400;
    color: #F08421;
    @media (max-width: 768px) {
        display: none;
  }
`
const MenuIcon = styled.div`
    z-index: 999;
    position: fixed;
    height: 10vh;
    display: none;
    align-items: center;
    top: 0;
    right:2rem;
    @media (max-width:768px) {
        display: flex;
    }
`

const Navbar = () => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);


    return(
        <Container>
                <LogoContainer>
                    <Logo />
                </LogoContainer>
                <MenuIcon onClick={handleClick}>
                    {
                        click
                        ?
                        < FiX style={{color: "#F08421",fontSize: "28px"}}  />
                        :
                        < FiMenu style={{color: "#F08421",fontSize: "28px"}}  />
                    }
                </MenuIcon>
                {
                    click
                    ?
                    <Menu>
                        <Corchete>{'{'}</Corchete>
                        <MenuItem 
                            to='hero'
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={closeMobileMenu}
                        >
                        Home
                        </MenuItem>
                        <MenuItem 
                            to='hiw'
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={closeMobileMenu}
                        >
                        How it works
                        </MenuItem>
                        <MenuItem 
                            to='features'
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={closeMobileMenu}
                        >
                        Features
                        </MenuItem>
                        <MenuItem 
                            to='pricing'
                            smooth={true}
                            offset={-70}
                            duration={500}
                            onClick={closeMobileMenu}
                        >
                        Pricing
                        </MenuItem>
                        <MenuItemDoc 
                            href='/documentation'
                        >
                        Documentation
                        </MenuItemDoc>
                        <Corchete>{'}'}</Corchete>
                    </Menu>
                    :
                    <Menu style={{left: "-100%"}}>
                        <Corchete>{'{'}</Corchete>
                        <MenuItem 
                            to='#'
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Home
                        </MenuItem>
                        <MenuItem 
                            to='hiw'
                            smooth={true}
                            offset={-150}
                            duration={500}
                        >
                        How it works
                        </MenuItem>
                        <MenuItem 
                            to='features'
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Features
                        </MenuItem>
                        <MenuItem 
                            to='pricing'
                            smooth={true}
                            offset={-70}
                            duration={500}
                        >
                        Pricing
                        </MenuItem>
                        <MenuItemDoc 
                            href='/documentation'
                        >
                        Documentation
                        </MenuItemDoc>
                        <Corchete>{'}'}</Corchete>
                    </Menu>
                }
                <User>
                    <a class="user-item2" href="https://app.gitdone.io/">Log in</a>
                </User>
        </Container>
    )
}

export default Navbar;