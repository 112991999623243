import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import '../../pages/style.css';

const Conatiner = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    padding-bottom: 10rem;
    @media (max-width: 768px) {
        height: 100%;
        margin-top: 2rem;
        padding-bottom: 0rem;
  }
`
const Statement = styled.h1 `
    max-width: 100%;
    font-family: "Averia Sans Libre";
    font-size: 62px;
    color: #3b3b3b;
    font-weight: 700;
    margin-bottom: 0px;
    @media (max-width: 1680px) {
        font-size: 48px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 30px;
    }
`

const Header = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    margin: 5rem 5rem 5rem 5rem;
    padding: 10px;
    @media (max-width: 768px) {
        margin: 2rem;
  }
`
const FeatureBox = styled.div`
    margin-top: 24px;
    display: grid;
    grid-template-columns: .7fr .3fr;
    gap: 5rem;
    grid-gap: 24px;
    justify-content: space-around;
    height: 100%;
    background-color: transparent;
    padding: 0rem 5rem 0rem 5rem;
    @media (max-width: 768px) {
        display:flex;
        flex-direction: column;
        padding: 0rem 2rem 2rem 2rem;
        margin-top: 0px;
  }
`
const FeatureContent = styled.div`
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    align-items: center;
`

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px;
    @media (max-width: 768px) {
        padding: 0px;
        margin-bottom: 2rem;
  }
`


const Features = () => {
    return(
        <Conatiner id="features">
            <Header>
                <Statement>Features</Statement>
                <ImageContainer>
                <StaticImage src='../../images/patterns-features.png'/>
                </ImageContainer>
            </Header>
            <FeatureBox>
                <ImageContainer>
                    <StaticImage src="../../images/feature-image.png" />
                </ImageContainer>
                <FeatureContent>
                    <div >
                        <h3>Gantt View</h3>
                        <h4>An up to date Gantt View thanks to the synching functionality to your Gitlab account where you can see each member´s contribution and timeline. Lets make sure we meet our objectives!</h4>
                    </div>
                    <div >
                        <h3>Member Contributions</h3>
                        <h4>See what each member is working on and the status of the work (labels) in a fast and simple manner.</h4>
                    </div>
                    <div >
                        <h3>Live Dashboard</h3>
                        <h4>Select your group(s) Get insight in a friendly and more executive way of the team´s contributions to help you ask the right questions and help the team chug along!</h4>
                    </div>
                    <div >
                        <h3>Recent Activity View</h3>
                        <h4>Need more data? We got your back! See what everyone is working on and help you identify potential churn or roadblocks or see how great we are all working together!</h4>
                    </div>
                </FeatureContent>
            </FeatureBox>
        </Conatiner>
    )
}

export default Features