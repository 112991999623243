import React from 'react';
import styled from 'styled-components';
import '../../pages/style.css';

const Conatiner = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 80vh;
background-color: transparent;
@media only screen and (max-width: 988px) {
    margin-bottom: 20rem;
}

`
const Statement = styled.h1 `
    max-width: 100%;
    font-family: "Averia Sans Libre";
    font-size: 62px;
    color: #3b3b3b;
    font-weight: 700;
    @media (max-width: 1680px) {
        font-size: 48px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 30px;
    }
`

const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5rem 5rem 0rem 5rem;
    width: 100%;
    padding: 10px;
    @media (max-width: 768px) {
        margin: 2rem;
        padding: 0px;
  }
`
const CardBox = styled.div`
margin-top: 24px;
display: grid;
grid-template-columns: 1fr 1fr 1fr;
gap: 5rem;
grid-gap: 24px;
justify-content: space-around;
height: 100%;
background-color: transparent;
padding: 0rem 5rem 0rem 5rem;
border: '1px solid #DDDDDD';
@media only screen and (max-width: 968px) {
    grid-template-columns: 1fr;
    grid-template-rows: .5fr .5fr .5fr;
}

`

const Card = styled.div`
display: grid;
grid-template-row: 1fr 1fr;
border-radius: 5px;
margin-top: 24px;
max-height: 50%;
box-shadow: 0px 10px 30px 10px rgba(0, 0, 0, 0.06); 
@media only screen and (max-width: 968px) {
    max-height: 100%;
}

`

const TextCard = styled.div`
border-radius: 5px;
margin-top: 24px;
padding: 24px;
max-height: 50%;
@media only screen and (max-width: 968px) {
    padding: 0px;
}

    
`
const Text = styled.div`
    max-width: 100%;
    font-family: "Open Sans";
    font-size: 28px;
    color: #6d7176;
    text-decoration: none;
    font-weight: 300;
    margin-bottom: 40px;
    @media only screen and (max-width: 1200px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 14px;
        margin-bottom: 20px;
    }
`
const TitleCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Open Sans";
    font-size: 40px;
    text-decoration: none;
    font-weight: 600;
    margin-bottom: 40px;
    @media (max-width: 1680px) {
        font-size: 20px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 16px;
        margin-top: 15px;
    }
`

const ContentCard = styled.div`
display: grid;
grid-template-row: 1fr 1fr;
border-radius: 5px;
font-family: "Open Sans";
font-size: 40px;
text-decoration: none;
font-weight: 600;
align-items: center;
justify-content: center;
@media (max-width: 1680px) {
    font-size: 20px;
    padding: 15px;
}

`

const Content2Card = styled.div`
    display: grid;
    grid-template-row: 1fr 1fr 1fr;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-family: "Open Sans";
    font-size: 20px;
    text-decoration: none;
    font-weight: 600;
    color: white;
    @media (max-width: 1680px) {
        font-size: 15px;
    }
    @media only screen and (max-width: 968px) {
        font-size: 14px;
        margin-bottom: 10px;
    }
`

const DefaultButton = styled.a `
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    padding: 24px;
    height: 0px;
    width: 15vw;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-size: 16px;
    font-family: "Open Sans";
    color: #F08421;
    @media only screen and (max-width: 968px) {
        font-size: 10px;
        padding: 10px;
    }
`

const ButtonContainer = styled.a `
    display: flex;
    align-items: center;
    justify-content: center;
`



const Pricing = () => {
    return(
        <Conatiner id="pricing">
            <Header>
                <Statement>Pricing</Statement>
            </Header>
            <CardBox>
                <TextCard>
                    <Text>
                    Please review our Pricing Model here, we don´t like to complicate things so we give you up to 5 free team members and then build up from there. We really want to help you build amazing products in time and on budget!
                    </Text>
                </TextCard>
                <Card>
                    <TitleCard >
                        30 Day Trial
                    </TitleCard>
                    <ContentCard style={{backgroundColor: '#0B315E'}}>
                        <Content2Card>5 free active users</Content2Card>
                        <ButtonContainer>
                            <DefaultButton href="https://app.gitdone.io/">Sign in</DefaultButton>
                        </ButtonContainer>
                    </ContentCard>
                </Card>
                <Card>
                    <TitleCard >
                        Standard Version
                    </TitleCard>
                    <ContentCard style={{backgroundColor: '#F08421'}}>
                        <Content2Card>USD 2.5 / User / Month billing</Content2Card>
                        <ButtonContainer>
                            <DefaultButton href="https://app.gitdone.io/">Sign in</DefaultButton>
                        </ButtonContainer>
                    </ContentCard>
                </Card>
            </CardBox>
        </Conatiner>
    )
}

export default Pricing