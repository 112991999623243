import * as React from "react"
import Navbar from "../components/navbar.js"
import Hero from "../components/sections/hero.js"
import Hiw from "../components/sections/Hiw.js"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Pricing from "../components/sections/pricing.js"
import Features from "../components/sections/features.js"
import ContactUs from "../components/sections/ContactUs.js"

// UI imports
import './style.css';

const IndexPage = () => (
<Layout>
    <SEO title="Home" />
    <body>
        <Navbar/>
        <Hero />
        <Hiw />
        <Features />
        <Pricing />
        <ContactUs />
</body>
</Layout>
)

export default IndexPage
