import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import '../../pages/style.css';

const ContactUs = () => {
    return(
        <>
        <div class="contact-us">
        <div class="contact-us-design">
            <StaticImage src="../../images/contact-us-design.svg" />
        </div>
        <div class="contact-us-form">
            <h4 class="sub-title">Have some questions?</h4>
            <h2 class="contact-us-title">Send us an email</h2>
            <form name="contact" method="POST" data-netlify="true" data-netlify-recaptcha="true" netlify-honeypot="bot-field">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value="contact" />
                <p class="input">
                    <label>Your Name: <input type="text" name="name" /></label>
                </p>
                <p class="input">
                    <label>Your Email: <input type="email" name="email" /></label>
                </p>
                <p>
                    <label>Inquiry: <textarea className="textArea" name="message"></textarea></label>
                </p>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <button style={{width: '80%'}} class="call-to-action-button" type="submit">Send Message</button>
                </div>
                
            </form>
        </div>
    </div>
    <div class="footer">
        <div class="footer-content">
            <h4 class="blue-background">2021 gitdone</h4>
            <div class="powered-by">
                <h4 class="blue-background">Powered by</h4>
                <StaticImage class="alio-logo" src="../../images/alio-logo.png" />
            </div>
        </div>
    </div>
    </>
    )
}

export default ContactUs
